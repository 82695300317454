@import '../../../../../scss/values';
@import '../../../../../scss/mixins/fonts';

.power-x-grouped-watchlist {
  .grouped-watchlist-root {
    .table-sort-header:not(table) {
      .table-header-row {
        .table-sort-header-cell {
          &.left_trade_position,
          &.right_trade_position {
            flex: 0 0 64px;
          }

          &.country_code {
            flex: 0 0 74px;
          }

          &.company_name {
            flex: 1 1 auto;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &.created_date {
            flex: 0 0 85px;
          }
        }
      }
    }

    .list {
      .item {
        .row {
          .cell {
            &.left_trade_position,
            &.right_trade_position {
              flex: 0 0 64px;
            }

            &.country_code {
              flex: 0 0 74px;
              display: flex;
              justify-content: center;
            }

            &.company_name {
              flex: 1 1 auto;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            &.created_date {
              flex: 0 0 85px;
            }
          }
        }
      }
    }
  }

  @media (max-width: $tablet-991px-width-trigger) {
    display: block;
    height: 172px;
  }
}
