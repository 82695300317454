@import '../../../../../scss/values';
@import '../../../../../scss/components/scroll';

.trading-headers {
  @media (max-width: $mobile-min-425px-width-trigger) {
    overflow-x: auto;
  }

  .right-view-toggle .cap {
    @extend .scroll-style;
    max-height: 41px;
    overflow: auto;
  }
}

.report-con-tbl {
  @media (min-width: $tablet-992px-width-trigger) {
    height: calc(100dvh - var(--top-nav-header-height) - 41px);
    max-height: calc(100dvh - var(--top-nav-header-height) - 41px);
  }

  table {
    padding-bottom: 10px;
  }

  .progress-inner-value {
    position: relative;
    left: 0;
    padding: 0 5px;
  }

  .progress-bar-parent {
    width: 100%;

    .progress-bar {
      top: 0;
      left: 0;
    }
  }
}
