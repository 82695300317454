@import '../../../../scss/values.scss';

[_ngcontent-scanner] .scanner-table:not(.scanner-table-v2),
[_ngcontent-watchlist] .watchlist-table {
  overflow-x: scroll !important;
}

.scanner-watchlist-tab-group {
  @media (max-width: 991px) {
    .drop:not(.show-for-mobile) {
      display: none;
    }
  }
}

.label-portfolio {
  display: flex;
  align-items: center;
  gap: 4px;
}

.add-watchlist {
  height: 28px;
  width: 28px;
  line-height: 28px;
  padding: 0 !important;
  min-width: 28px !important;
  position: absolute !important;
  right: 8px;
  top: 3px;
  z-index: 3
}

@media screen and (min-width: $mobile-max-width-trigger) {
  .add-watchlist {
    z-index: 2 !important;
    right: 8px !important;
  }
}

@media screen and (min-width: $tablet-min-width-trigger) {
  .app-run-scanner-button {
    display: none !important;
  }
}

.app-run-scanner-button {
  @media screen and (max-width: $mobile-min-425px-width-trigger) {
    display: none !important;
  }
}
