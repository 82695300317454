<app-maintenance></app-maintenance>

<div
  [class.loading]="isLoading()"
  *ngIf="!showMaintenance"
  class="powerx-main powerx-page scroll-style"
>
  <app-strategy-skeleton [isVisible]="isLoading() && !showMaintenance"></app-strategy-skeleton>

  <div
    #subMenu
    class="main-menu"
  >
    <div class="mobile-header">
      <mat-icon
        [svgIcon]="'power-x-icon'"
        class="sub-menu-strategy-icon"
      ></mat-icon>
      <p class="menu-title">
        {{ strategiesNames.PowerX }}
      </p>
    </div>

    <div class="search-bar-section">
      <div class="header-container desktop-header">
        <p class="header with-divider">
          {{ strategiesNames.PowerX }}
        </p>
      </div>
      <div class="search-bar">
        <app-search-bar [isPowerX]="true"></app-search-bar>
      </div>
    </div>

    <div class="long-position">
      <button
        [ngClass]="tradePosition === TradePositions.LongAndShort ? 'grey' : ''"
        [matMenuTriggerFor]="menu"
        [class]="tradePosition"
        mat-button
        class="long-position-button"
      >
        <!--  TODO: do not use functions in templates  -->
        {{ getTradePositionName(tradePosition) }}
      </button>
      <mat-menu
        #menu="matMenu"
        class="position-dropdown"
      >
        <ng-container
          *ngFor="let tPosition of [TradePositions.LongOnly, TradePositions.ShortOnly, TradePositions.LongAndShort]"
        >
          <button
            [ngStyle]="{ 'background-color': tradePosition === tPosition ? 'var(--selected-item-bg)' : '' }"
            (click)="updatePosition(tPosition)"
            mat-menu-item
          >
            <!--  TODO: do not use functions in templates  -->
            {{ getTradePositionName(tPosition) }}
          </button>
        </ng-container>
      </mat-menu>
    </div>

    <div class="account-size-container">
      <div
        (click)="positionSizing()"
        class="account-size"
      >
        @let formattedValues = accountSizeFormattedValues();

        <div class="size">
          <span>Account</span>
          <p>${{ formattedValues.accountSizeFormatted }}</p>
        </div>
        <div class="risk">
          <span>Risk</span>
          <div class="risk-inner-div">
            <p>{{ formattedValues.accountRiskPercentFormatted }}%</p>
            <p>${{ formattedValues.riskAmountPerTradeFormatted }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="run-scanner-container">
      @if (!showMaintenance) {
        <app-run-scanner-button></app-run-scanner-button>
      }
    </div>

    <div class="print-data-container">
      @if (!isMobileOS && !showMaintenance) {
        <div class="print-data-group">
          <button
            (click)="openPrintModal()"
            mat-icon-button
            class="open-print-button"
          >
            <mat-icon
              [svgIcon]="'print'"
              class="print-icon"
            ></mat-icon>
          </button>
        </div>
      }
    </div>
  </div>
  <div
    [ngStyle]="{
      '--custom-width-with-pinned-order-panel': showOrderPanel ? '370px' : '0px',
      '--search-bar-height': subMenu.offsetHeight + 'px',
    }"
    class="left-side"
  >
    <app-chart-menu></app-chart-menu>
  </div>
  <app-trading-panel-order-panel *ngIf="showOrderPanel"></app-trading-panel-order-panel>
  <div class="right-side">
    <as-split
      direction="horizontal"
      class="all-section"
      gutterSize="4"
    >
      <as-split-area>
        <as-split
          direction="vertical"
          gutterSize="4"
        >
          <as-split-area
            [size]="35"
            minSize="25"
            class="right-one"
          >
            <app-scanner-watchlist-panel></app-scanner-watchlist-panel>
          </as-split-area>
          <as-split-area
            [size]="65"
            minSize="25"
            class="right-two h-less-991"
            gutterSize="11"
          >
            <app-data-window [isPrint]="false"></app-data-window>
          </as-split-area>
        </as-split>
      </as-split-area>
    </as-split>
  </div>
</div>
